import { TextToSpeech } from '@capacitor-community/text-to-speech'
import removeMarkdown from 'remove-markdown'

import i18n from '@/i18n-config'

import { errorAlert } from '@/utils/ion-alert'

export function isSpeakable(text: string) {
    const trimmedText = removeMarkdown(text, { stripListLeaders: false }).trim()
    return /[a-z0-9]/i.test(trimmedText) // Contains at least one alphanumeric character
}

/**
 * Uses native ScreenReader to speak text in the language of current
 * i18n locale.
 *
 * @param text content to speak, markdown formatted or plain text
 */
export async function speak(text: string) {
    try {
        if (text.length === 0) return
        await TextToSpeech.speak({
            text: removeMarkdown(text, { stripListLeaders: false }),
            lang: `${i18n.global.locale.value}-US`,
        })
    } catch (error) {
        if (
            error instanceof window.SpeechSynthesisErrorEvent &&
            error.error === 'interrupted'
        ) {
            // New utterance started, ignore
            return
        }
        errorAlert({
            error: i18n.global.t('TextToSpeech.screenReaderUnavailable'),
        })
    }
}

export async function stopSpeaking() {
    return TextToSpeech.stop()
}
