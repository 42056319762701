import { computed, ref } from 'vue'

import {
    firestore,
    serverTimestamp,
    auth,
    deleteField,
    FirestoreWriteBatch,
} from '@/firebase-config'
import i18n from '@/i18n-config'

import useAdminSettings from '@/composables/global/use-admin-settings'
import useChats from '@/composables/global/use-chats'
import useCourses from '@/composables/global/use-courses'
import useViewer from '@/composables/global/use-viewer'

import { createAuthUser, AuthUserData } from '@/utils/callable-cloud-functions'
import { logger } from '@/utils/debug'
import { getCollectionDocs } from '@/utils/firestore'

import {
    User,
    UserRole,
    UserStatus,
    UserCourses,
    UserProperties,
    Timezone,
} from '@/models/user'

const debug = false

export type UserData = {
    id?: string
    email?: string
    courses: UserCourses
    guides: string[]
    name: string
    displayName: string
    properties: UserProperties
    researchId: string
    roles: UserRole[]
    status: UserStatus
    tags: string[]
    timezone: Timezone
    archived?: boolean
}

const guideUsers = ref<User[]>([])
const users = ref<User[]>([])

const pageLockedUsers = computed(() =>
    users.value.filter((u) => u.lockedPage !== undefined)
)

const usersCollectionRef = firestore.collection('users')

export default function () {
    const { isAdmin, isManager, isGuide, viewer, updateViewerSettings } =
        useViewer()
    const { activeCourse, courses } = useCourses()

    const activeCourseUsers = computed(() =>
        users.value.filter(
            (user) =>
                activeCourse.value &&
                user.courses &&
                activeCourse.value.id in user.courses &&
                !user.archived
        )
    )

    const archivedUsersInCourse = computed(() =>
        users.value.filter(
            (user) =>
                activeCourse.value &&
                user.courses &&
                activeCourse.value.id in user.courses &&
                user.archived
        )
    )

    async function batchUpdateUser(
        userData: Partial<UserData>,
        batch: FirestoreWriteBatch
    ) {
        logger(debug, userData)
        if (viewer.value === undefined) throw 'Viewer not initialized'
        if (!userData.id) throw 'User ID missing'

        const { batchUpdateChatsDisplayName } = useChats()

        const updateDisplayName = 'displayName' in userData
        if (updateDisplayName && userData.id === viewer.value.id) {
            updateViewerSettings({ displayName: userData.displayName })
        } else if (updateDisplayName) {
            await batchUpdateChatsDisplayName(
                userData.displayName || userData.name,
                userData.id,
                batch
            )
        }

        batch.update(firestore.collection('users').doc(userData.id), {
            ...userData,
            updatedAt: serverTimestamp(),
            updatedBy: viewer.value.id,
        })
    }

    async function fetchAgencyManagers(agencyId?: string) {
        logger(debug)
        if (agencyId === undefined) return []

        const agencyManagersSnapshot = await usersCollectionRef
            .where('roles', 'array-contains', 'manager')
            .where('properties.agency', '==', agencyId)
            .get()

        if (!agencyManagersSnapshot.empty) {
            return getCollectionDocs<User>(agencyManagersSnapshot)
        } else {
            return []
        }
    }

    async function fetchGuideUsers(agencyId?: string) {
        logger(debug)
        const { adminEnabled } = useAdminSettings()

        if (isAdmin.value || !agencyId || !adminEnabled.value.agencies) {
            const guideUsersCollection = await usersCollectionRef
                .where('roles', 'array-contains', 'guide')
                .get()
            guideUsers.value = getCollectionDocs<User>(guideUsersCollection)
        } else {
            const guideUsersCollection = await usersCollectionRef
                .where('roles', 'array-contains', 'guide')
                .where('properties.agency', '==', agencyId)
                .get()
            guideUsers.value = getCollectionDocs<User>(guideUsersCollection)
        }
    }

    function filterUsersCollection(viewerId: string, agencyId?: string) {
        logger(debug, agencyId)

        const { adminEnabled } = useAdminSettings()

        if (isAdmin.value) {
            return usersCollectionRef
        }

        if (agencyId && adminEnabled.value.agencies) {
            if (isGuide.value) {
                return usersCollectionRef
                    .where('guides', 'array-contains', viewerId)
                    .where('properties.agency', '==', agencyId)
            } else if (isManager.value) {
                return usersCollectionRef.where(
                    'properties.agency',
                    '==',
                    agencyId
                )
            }
        } else {
            if (isGuide.value) {
                return usersCollectionRef.where(
                    'guides',
                    'array-contains',
                    viewerId
                )
            } else if (isManager.value) {
                return usersCollectionRef
            }
        }
    }

    async function fetchUsers() {
        logger(debug)
        if (viewer.value === undefined) throw 'Viewer not initialized'

        await fetchGuideUsers(viewer.value.properties?.agency)

        const usersCollection = await filterUsersCollection(
            viewer.value.id,
            viewer.value.properties?.agency
        )?.get()

        if (usersCollection) {
            users.value = getCollectionDocs<User>(usersCollection)
            // Firebase's orderBy sorting is case-sensitive only,
            // will need to sort the list here so lowercased names
            // are not grouped to bottom.
            // Intl.collator is useful for sorting names containing accented character
            const collator = Intl.Collator(i18n.global.locale.value)
            users.value.sort((a, b) => collator.compare(a.name, b.name))
        } else {
            users.value = []
        }
    }

    /** Return a list containing the displayNames of users matching the given user IDs
     *  If a displayName is not available, then the .name value is used.
     *  If user is not found, then the user ID is used.
     */
    function getDisplayNames(userIds: string[]) {
        logger(debug, userIds)

        return userIds.map((id) => {
            const user = getUser(id)
            return user?.displayName || user?.name || id
        })
    }

    function getUserTagsByUser(userId?: string) {
        logger(debug, userId)
        if (userId === undefined) return []

        const user = getUser(userId)
        const userCoursesIds = Object.keys(user?.courses ?? {})
        const userCourses = userCoursesIds.map((courseId) =>
            courses.value.find((course) => course.id === courseId)
        )

        return [
            ...new Set(
                userCourses.flatMap((userCourse) => {
                    if (userCourse?.userTags) {
                        //check for Map typing, since firestore stores maps as plain objects instead
                        //new Map is necessary, using object keys doesn't work
                        const userTagsMap =
                            userCourse.userTags instanceof Map
                                ? userCourse.userTags
                                : new Map(Object.entries(userCourse.userTags))

                        return Array.from(userTagsMap.keys()).sort()
                    } else {
                        return []
                    }
                })
            ),
        ]
    }

    function getUsersInCourse(courseId: string) {
        logger(debug, courseId)

        return users.value.filter((user) => {
            if (user.courses) {
                return courseId in user.courses
            } else {
                return false
            }
        })
    }

    function getUsersByStatusInCourse(status: UserStatus, courseId: string) {
        logger(debug, { status, courseId })

        const usersInCourse = getUsersInCourse(courseId)
        return usersInCourse.filter((user) => user.status === status)
    }

    function getUsersByTagInCourse(tag: string, courseId: string) {
        logger(debug, { tag, courseId })

        const usersInCourse = getUsersInCourse(courseId)
        return usersInCourse.filter((user) => user.tags?.includes(tag))
    }

    async function handleExistingUser(authUserData: AuthUserData) {
        logger(debug, authUserData)
        const authMethods = await auth.fetchSignInMethodsForEmail(
            authUserData.email
        )
        const hasAuthAccount = authMethods.length > 0
        if (hasAuthAccount) {
            throw i18n.global.t('User.userExistsError', {
                userId: authUserData.email,
            })
        } else {
            // user still needs auth account
            const { data } = await createAuthUser(authUserData)
            return data
        }
    }

    async function batchAddUser(
        userData: UserData,
        password: string,
        batch: FirestoreWriteBatch
    ) {
        logger(debug, userData, password)
        if (viewer.value === undefined) throw 'Viewer not initialized'
        if (!userData.email) throw 'User email missing'
        if (password === '') throw 'Password is empty'

        const { adminEnabled } = useAdminSettings()

        const authUserData = {
            email: userData.email,
            roles: userData.roles,
            createdBy: viewer.value.id,
            password,
        }

        const { data } = await createAuthUser(authUserData)

        const userDoc = firestore.collection('users').doc(data.uid)
        const userSnapshot = await userDoc.get()
        if (userSnapshot.exists) {
            return handleExistingUser(authUserData)
        }

        batch.set(userDoc, {
            ...userData,
            id: data.uid,
            options: [
                ...(adminEnabled.value.autoplay ? ['autoplay'] : []),
                'notifications',
            ],
            createdAt: serverTimestamp(),
            createdBy: viewer.value.id,
        })

        return data
    }

    async function removeUserLockedPage(userId: string) {
        logger(debug, userId)
        if (viewer.value === undefined) throw 'Viewer not intialized'

        await firestore.collection('users').doc(userId).update({
            lockedPage: deleteField(),
            updatedAt: serverTimestamp(),
            updatedBy: viewer.value.id,
        })

        const userIndex = users.value.findIndex((u) => u.id === userId)
        if (userIndex === -1) return
        delete users.value[userIndex].lockedPage
    }

    // Misc

    function getUser(userId: string) {
        logger(debug, getUser)

        return users.value.find((user) => user.id === userId)
    }

    async function isUserInActiveCourse(userId: string) {
        logger(debug, userId)
        if (viewer.value === undefined) throw 'Viewer not initialized'

        if (users.value.length === 0) {
            await fetchUsers()
        }

        // Using viewer's activeCourse as this function
        // may be called before 'activeCourse' in useCourses is set,
        // the issue is unresolvable with appDataInitialized() called
        // inside the global .beforeEach route guard.
        const usersInActiveCourse = users.value.filter((user) => {
            if (viewer.value?.activeCourse && user.courses) {
                return viewer.value.activeCourse in user.courses
            } else {
                return false
            }
        })

        return usersInActiveCourse.some((user) => user.id === userId)
    }

    function deinitUsers() {
        logger(debug)
        guideUsers.value = []
        users.value = []
    }

    async function deleteUser(userId: string) {
        logger(debug, userId)

        await firestore.collection('users').doc(userId).delete()
        users.value = users.value.filter((user) => user.id !== userId)
    }

    return {
        activeCourseUsers,
        archivedUsersInCourse,
        guideUsers,
        pageLockedUsers,
        users,

        batchAddUser,
        batchUpdateUser,
        deleteUser,
        fetchAgencyManagers,
        fetchGuideUsers,
        fetchUsers,
        getDisplayNames,
        getUser,
        getUsersInCourse,
        getUserTagsByUser,
        getUsersByStatusInCourse,
        getUsersByTagInCourse,
        isUserInActiveCourse,
        removeUserLockedPage,
        deinitUsers,
    }
}
