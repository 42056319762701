import { computed, ref, Ref, readonly, DeepReadonly } from 'vue'

import { firestore, serverTimestamp } from '@/firebase-config'

import useCourses from '@/composables/global/use-courses'
import useLevelPage from '@/composables/global/use-level-page'
import useProgress from '@/composables/global/use-progress'
import useViewer from '@/composables/global/use-viewer'

import { logger } from '@/utils/debug'
import {
    cleanObjForFirestore,
    docData,
    generateDocId,
    getCollectionDocs,
    shiftDocNums,
} from '@/utils/firestore'

import { Level } from '@/models/courses/level'
import { User } from '@/models/user'

const debug = false

const levels: Ref<Level[]> = ref([])

export default function () {
    const { activeCourse } = useCourses()
    const { viewer } = useViewer()
    const { progress } = useProgress()

    const assignedCustomLevels = computed(
        () => customLevels.value || levels.value
    )

    const customLevels = computed(() => {
        let customLevels: Level[] | undefined = undefined
        if (viewer.value === undefined) return undefined

        const user = viewer.value
        const courseId = activeCourse.value?.id

        if (
            courseId &&
            user.customLevelsForCourses &&
            user.customLevelsForCourses[courseId]
        ) {
            customLevels = []
            user.customLevelsForCourses[courseId].forEach(
                (customLevel: string) => {
                    const level = levels.value.find((l) => l.id === customLevel)
                    if (level && customLevels) {
                        customLevels.push(level)
                    }
                }
            )
        }

        return customLevels
    })

    async function fetchLevels() {
        logger(debug)
        if (activeCourse.value === undefined) {
            throw 'Active course not initialized'
        }

        const levelsSnapshot = await firestore
            .collection('courses')
            .doc(activeCourse.value.id)
            .collection('levels')
            .orderBy('num')
            .get()

        levels.value = getCollectionDocs<Level>(levelsSnapshot)
    }

    async function getCustomLevelsForUser(userId: string) {
        logger(debug)
        const user = await firestore
            .collection('users')
            .doc(userId)
            .get()
            .then(docData<User>)

        const courseId = activeCourse.value?.id
        if (!courseId) throw 'Active course not initialized'
        if (
            user.customLevelsForCourses &&
            user.customLevelsForCourses[courseId] &&
            user.customLevelsForCourses[courseId].length > 0
        ) {
            const customLevels: Level[] = []
            user.customLevelsForCourses[courseId].forEach(
                (customLevel: string) => {
                    const level = levels.value.find((l) => l.id === customLevel)
                    if (level) {
                        customLevels.push(level)
                    }
                }
            )
            return customLevels
        }
        return undefined
    }

    async function updateCustomLevels(
        userId: string,
        courseId: string,
        customLevels: string[] | undefined
    ) {
        // update the user custom levels
        logger(debug)

        const userDoc = firestore.collection('users').doc(userId)
        const user = await userDoc.get().then(docData<User>)

        if (customLevels === undefined) {
            if (user.customLevelsForCourses) {
                delete user.customLevelsForCourses[courseId]
            } else {
                delete user.customLevelsForCourses
            }
        } else {
            if (!user.customLevelsForCourses) {
                user.customLevelsForCourses = {}
            }
            user.customLevelsForCourses[courseId] = customLevels
        }
        await userDoc.update({
            customLevelsForCourses: user.customLevelsForCourses,
        })
    }

    async function addLevel(fields: { title: string }) {
        logger(debug, fields)
        if (activeCourse.value === undefined) {
            throw 'Active course not initialized'
        }
        if (viewer.value === undefined) throw 'Viewer not initialized'

        const levelsCollection = firestore
            .collection('courses')
            .doc(activeCourse.value.id)
            .collection('levels')

        const lastLevelSnapshot = await levelsCollection
            .orderBy('num')
            .limitToLast(1)
            .get()

        let newLevelNum = 1
        if (!lastLevelSnapshot.empty) {
            // Add new level after existing last level
            newLevelNum = lastLevelSnapshot.docs[0].get('num') + 1
        }

        const batch = firestore.batch()

        // Add new level
        const newLevelDoc = levelsCollection.doc()
        batch.set(newLevelDoc, {
            ...cleanObjForFirestore('set', fields),
            num: newLevelNum,
            updatedBy: viewer.value.id,
            updatedAt: serverTimestamp(),
            createdAt: serverTimestamp(),
        })

        // Add initial page to new level
        const newPageDoc = newLevelDoc.collection('pages').doc()
        batch.set(newPageDoc, {
            title: '',
            num: 1,
            inBranch: false,
            createdAt: serverTimestamp(),
            updatedAt: serverTimestamp(),
            createdBy: viewer.value.id,
        })

        await batch.commit()
        return newLevelDoc.id
    }

    async function deleteLevel(level?: DeepReadonly<Level>) {
        logger(debug, level)
        if (level === undefined) throw 'Invalid level'
        if (activeCourse.value === undefined) {
            throw 'Active course not initialized'
        }

        const levelsCollection = firestore
            .collection('courses')
            .doc(activeCourse.value.id)
            .collection('levels')
        const levelDoc = levelsCollection.doc(level.id)

        const batch = firestore.batch()

        // Update affected levels' num fields
        await shiftDocNums(batch, levelsCollection, {
            shift: -1,
            fromNum: level.num,
        })

        // Delete level
        batch.delete(levelDoc)

        // Delete all pages in level
        const levelPagesSnapshot = await levelDoc.collection('pages').get()
        levelPagesSnapshot.forEach((levelPageDoc) => {
            batch.delete(levelPageDoc.ref)
        })

        await batch.commit()
        await fetchLevels()
    }

    async function updateLevel(
        levelId: string,
        fields: Omit<
            Level,
            'id' | 'num' | 'updatedAt' | 'updatedBy' | 'createdAt'
        >
    ) {
        logger(debug, fields)
        if (activeCourse.value === undefined) {
            throw 'Active course not initialized'
        }
        if (viewer.value === undefined) throw 'Viewer not initialized'

        // Update level
        await firestore
            .collection('courses')
            .doc(activeCourse.value.id)
            .collection('levels')
            .doc(levelId)
            .update({
                ...cleanObjForFirestore('update', fields, {
                    markAllUnwantedFieldsForDeletion: true,
                }),
                updatedBy: viewer.value.id,
                updatedAt: serverTimestamp(),
            })

        await fetchLevels()
    }

    async function reorderLevels(sortFn: (levels: Level[]) => Level[]) {
        logger(debug)
        if (activeCourse.value === undefined) {
            throw 'Active course not initialized'
        }

        const levelsCollection = firestore
            .collection('courses')
            .doc(activeCourse.value.id)
            .collection('levels')

        const batch = firestore.batch()

        levels.value = sortFn(levels.value)
        levels.value.forEach((level, index) => {
            const levelDoc = levelsCollection.doc(level.id)
            batch.update(levelDoc, { num: index + 1 })
            level.num = index + 1
        })

        await batch.commit()
    }

    async function getPercentComplete(level: DeepReadonly<Level>) {
        logger(debug, level.id)
        if (activeCourse.value === undefined) {
            throw 'Active course not initialized'
        }

        const levelProgress = progress.value?.levelsProgress?.[level.id]
        if (levelProgress === undefined) return 0

        const { getProgressedPageCount, getTotalRootPageCount } = useLevelPage(
            level.id
        )
        const progressedPageCount = await getProgressedPageCount(
            levelProgress.furthestPage
        )
        const totalPageCount = await getTotalRootPageCount()
        return Math.floor((progressedPageCount / totalPageCount) * 100)
    }

    function generateNudgeId(levelId: string) {
        logger(debug)
        if (activeCourse.value === undefined)
            throw 'Active course not initialized'
        const elementsCollection = firestore
            .collection('courses')
            .doc(activeCourse.value.id)
            .collection('levels')
            .doc(levelId)
            .collection('nudges')
        return generateDocId(elementsCollection)
    }

    function deinitLevels() {
        logger(debug)
        levels.value = []
    }

    return {
        levels: readonly(levels),
        assignedCustomLevels,

        addLevel,
        deinitLevels,
        deleteLevel,
        fetchLevels,
        generateNudgeId,
        getCustomLevelsForUser,
        getPercentComplete,
        reorderLevels,
        updateLevel,
        updateCustomLevels,
    }
}
