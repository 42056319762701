import { computed, ref, ComputedRef, DeepReadonly, Ref, readonly } from 'vue'

import { firestore, serverTimestamp } from '@/firebase-config'
import i18n from '@/i18n-config'
import { replaceQueryInRoute } from '@/router'

import useLevels from '@/composables/global/use-levels'
import useLogs from '@/composables/global/use-logs'
import useProgress from '@/composables/global/use-progress'
import useResources from '@/composables/global/use-resources'
import useViewer from '@/composables/global/use-viewer'

import { logger } from '@/utils/debug'
import { getCollectionDocs, cleanObjForFirestore } from '@/utils/firestore'
import { errorAlert } from '@/utils/ion-alert'

import { AccessDefault, Course, CourseOption } from '@/models/course'

const debug = false

// State
const courses: Ref<Course[]> = ref([])
const activeCourseId: Ref<string | undefined> = ref()
const activeCourse: ComputedRef<Course | undefined> = computed(() => {
    const cachedCourse = courses.value?.find(
        (course) => course.id === activeCourseId.value
    )
    return cachedCourse
})

type CourseAccessDefault = {
    id: string
    accessString: AccessDefault
}

const coursesAccessDefaults: ComputedRef<CourseAccessDefault[] | undefined> =
    computed(() => {
        const accessDefaults = courses.value?.map((course) => {
            return {
                id: course.id,
                accessString: course.properties?.accessDefault ?? 'first',
            }
        })
        return accessDefaults
    })

const { isAdmin, updateActiveCourse, viewer, viewersCourseIds } = useViewer()
const coursesCollection = firestore.collection('courses')

const { locale } = i18n.global

export default function () {
    // Courses

    async function loadCourse(courseId: string) {
        logger(debug, courseId)

        const { loadViewerProgress } = useProgress()
        const { fetchLevels } = useLevels()
        const { deinitLogs } = useLogs()
        const { fetchResources } = useResources()

        deinitLogs()

        const courseIdExists = courses.value.some(
            (course) => course.id === courseId
        )

        if (courseIdExists) {
            activeCourseId.value = courseId
        } else {
            await errorAlert({
                error: i18n.global.t('Courses.courseNotFound'),
            })
            activeCourseId.value =
                viewer.value?.activeCourse || courses.value[0].id
        }

        if (courses.value.length === 0) {
            throw 'Missing course enrollment'
        } else if (activeCourse.value === undefined) {
            activeCourseId.value = courses.value[0].id
        }
        if (viewer.value === undefined) throw 'Viewer not initialized'

        locale.value = activeCourse.value?.language ?? 'en'

        await Promise.all([
            loadViewerProgress(),
            fetchLevels(),
            fetchResources(),
        ])

        updateActiveCourse(activeCourseId.value)
        replaceQueryInRoute({ course: activeCourseId.value })
    }

    async function fetchCourses() {
        logger(debug)

        const coursesSnapshot = await coursesCollection.get()
        let coursesData = getCollectionDocs<Course>(coursesSnapshot)
        coursesData.sort((a, b) => (a.num ?? 0) - (b.num ?? 0)) // Null-check num for legacy courses

        if (!isAdmin.value) {
            coursesData = coursesData.filter((course) => {
                return viewersCourseIds.value?.includes(course.id)
            })
        }

        logger(debug, coursesData)
        courses.value = coursesData
    }

    async function addCourse(fields: Partial<Course>) {
        logger(debug, fields)
        if (!viewer.value) return Promise.reject('Viewer not initialized')

        const collection = coursesCollection
        const batch = firestore.batch()

        // Add Course
        const newCourseDoc = collection.doc()
        batch.set(newCourseDoc, {
            ...cleanObjForFirestore('set', fields),
            num: courses.value.length + 1,
            updatedBy: viewer.value.id,
            createdAt: serverTimestamp(),
        })

        // Add Level
        const newLevelDoc = newCourseDoc.collection('levels').doc()
        batch.set(newLevelDoc, {
            title: '',
            num: 1,
            updatedBy: viewer.value.id,
            createdAt: serverTimestamp(),
        })

        // Add Page
        const newPageDoc = newLevelDoc.collection('pages').doc()
        batch.set(newPageDoc, {
            title: '',
            num: 1,
            inBranch: false,
            createdAt: serverTimestamp(),
            updatedBy: viewer.value.id,
        })

        await batch.commit()
        await fetchCourses()
        return newCourseDoc.id
    }

    async function updateCourse(
        courseId: string,
        fields: Partial<DeepReadonly<Course>>
    ) {
        logger(debug, fields)
        if (!viewer.value) return Promise.reject('Viewer not initialized')

        await coursesCollection.doc(courseId).update({
            ...cleanObjForFirestore('update', fields, {
                markAllUnwantedFieldsForDeletion: true,
            }),
            updatedBy: viewer.value.id,
            updatedAt: serverTimestamp(),
        })
        // Update the current locale if updated course is the active course
        if (activeCourseId.value === courseId && fields.language) {
            locale.value = fields.language
        }

        await fetchCourses()
    }

    async function reorderCourses(sortFn: (courses: Course[]) => Course[]) {
        logger(debug)
        if (activeCourse.value === undefined) {
            throw 'Active course not initialized'
        }

        const batch = firestore.batch()

        courses.value = sortFn(courses.value)
        courses.value.forEach((course, index) => {
            const courseDoc = coursesCollection.doc(course.id)
            batch.update(courseDoc, { num: index + 1 })
            course.num = index + 1
        })

        await batch.commit()
    }

    function isOptionSet(courseOption: CourseOption) {
        if (activeCourse.value?.options?.includes(courseOption)) {
            return true
        } else {
            return false
        }
    }

    function getAllUserTagsFromCourses() {
        return [
            ...new Set(
                courses.value.flatMap((course) => {
                    if (course.userTags) {
                        const userTagsMap =
                            course.userTags instanceof Map
                                ? course.userTags
                                : new Map(Object.entries(course.userTags))

                        return Array.from(userTagsMap.keys())
                    } else {
                        return []
                    }
                })
            ),
        ]
    }

    function deinitCourses() {
        logger(debug)
        courses.value = []
        activeCourseId.value = undefined
    }

    return {
        // State
        activeCourse,
        courses: readonly(courses),
        coursesAccessDefaults,

        // Functions
        addCourse,
        deinitCourses,
        fetchCourses,
        getAllUserTagsFromCourses,
        isOptionSet,
        loadCourse,
        reorderCourses,
        updateCourse,
    }
}
